<template>
	<div id="login">
		<v-form
			ref="form"
			v-model="form.valid"
			lazy-validation
		>
			<v-container fluid>
        <v-row>
          <v-col sm="6">
						<v-row>
							<v-col align="center">
								<h1>Login</h1>
								<p>Don't have an account yet? <router-link exact to="/register">Register now!</router-link></p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-row dense>
									<v-col>
										<v-text-field
											filled
											label="Email"
											v-model="form.fields.email"
											placeholder="name@domain.com"
											:rules="form.validation.emailRules"
											required
										></v-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col>
										<v-text-field
											filled
											label="Password"
											v-model="form.fields.password"
											placeholder="Password"
											type="password"
											:rules="form.validation.passwordRules"
											required
										></v-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col sm="12" align="right">
										<router-link class="ma-1 pr-3" x-large color="error" to="/forget-password" plain>Forget Password?</router-link> 
										<v-btn color="deep-orange darken-3" dark x-large @click.prevent="login">
											Login
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
          <v-col sm="6" align="center">
            <pp-shades>
              <h2 class="white--text">Please login to play Parlay Poker!</h2>
							<br>Please enter your e-mail and password to log into our game.<br><br>If you have forgotten your password click on the Forgot Password link to set a new one.
            </pp-shades>
          </v-col>
        </v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: "Login.vue",
		data() {
			return {
				form: {
					valid: true,
					validation:{
						emailRules: [
							v => !!v || 'E-mail is required',
							v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
						],
						passwordRules: [
							v => !!v || 'Password is required',
						],
					},
					fields:{
						email: "",
						password: "",
					},
				},
			};
		},
		created(){
			if(this.$route.params.verified == 'true'){
				this.$root.snackbar.message = 'You email is now verified!';
				this.$root.snackbar.open = true;
				this.$router.replace('/login');
			}
		},
		computed: {},
		methods: {
			async login() {
				let self = this;
				if(this.$refs.form.validate()){
					await this.$store
						.dispatch("login", this.form.fields)
						.then(() => {
							self.$root.snackbar.message = 'Login Successful!';
							self.$root.snackbar.open = true;
							self.$router.replace('/dashboard');
						})
						.catch((err) => {
							self.$root.snackbar.message = err.message;
							self.$root.snackbar.open = true;
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	h1 {
		line-height: 1.2em;
	}
	textarea {
		height: 13rem;
	}
	.map {
		border-radius: 10px;
		overflow: hidden;
		border: solid 1px #cfd8dc;
	}
</style>
